import React from "react";

import PropTypes from "prop-types";
import { TextArea } from "@blueprintjs/core";

export default function TextWidget({ area, value, onChange, ...props }) {
  const width = 60;
  
  props.value = value || "";

  function onEventChange(e) {
    if (e) onChange(e.target.value || undefined);
  }

  if (area) {
    return <TextArea cols={width} onChange={onEventChange} {...props} />;
  }
  return <input type="text" size={width} onChange={onEventChange} {...props} />;
}

TextWidget.propTypes = {
  name: PropTypes.string.isRequired,
  area: PropTypes.bool
};

TextWidget.defaultProps = {
  area: false
};
